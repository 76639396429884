import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Outlet } from "react-router-dom";
import { loginRequest } from "../authConfig";
import { Navbar } from "../components/Navbar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export const PanelPage = () => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={loginRequest}
    >
      <div className="min-h-screen min-w-screen bg-base-200">
        <div className="p-4 max-w-screen-2xl mx-auto">
          <div className="mb-5">
            <Navbar />
          </div>
          <Outlet />
        </div>
        <ToastContainer />
      </div>
    </MsalAuthenticationTemplate>
  );
};
