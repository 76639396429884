import React from "react";
import "./index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import { DashboardPage } from "./panel-pages/DashboardPage";
import { PanelPage } from "./panel-pages/PanelPage";
import { CalendarPage } from "./panel-pages/CalendarPage";
import { CustomerCalendarPage } from "./pages/CustomerCalendarPage";
import { CalendarSettings } from "./components/calendar-settings/CalendarSettings";
import { WholeDayAvailabilitySettings } from "./components/calendar-settings/WholeDayAvailabilitySettings";
import { ReservationFieldsSettings } from "./components/calendar-settings/ReservationFieldsSettings";
import { RemoveCalendar } from "./components/calendar-settings/RemoveCalendar";
import { ReservationsPage } from "./panel-pages/ReservationsPage";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:calendarId",
    element: <CustomerCalendarPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/panel",
    element: <PanelPage />,
    children: [
      {
        path: "",
        element: <DashboardPage />,
      },
      {
        path: "calendar/:calendarId/reservations",
        element: <ReservationsPage />,
      },
      {
        path: "calendar/:calendarId/settings",
        element: <CalendarPage />,
        children: [
          {
            path: "",
            element: <CalendarSettings />,
          },
          {
            path: "availability",
            element: <WholeDayAvailabilitySettings />,
          },
          {
            path: "fields",
            element: <ReservationFieldsSettings />
          },
          {
            path: "remove",
            element: <RemoveCalendar />
          }
        ],
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={router} />
    </MsalProvider>
  </React.StrictMode>
);
